@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap);
.loading-screen {
    text-align: center;
    width: 100vw;
    height: 100vh;
}

.loading-screen h1 {
    margin-top: 30vh;
    font-weight: 700;
    font-size: 3em !important;
    font-family: 'Arial' !important;
}

.loader {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #1dbc98;
    border-radius: 50%;
    width: 8em;
    height: 8em;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (min-width: 1050px) {
    #title {
        font-size: 2.5em !important;
    }

    h1 {
        font-size: 1.8em;
    }

    h2 {
        font-size: 1.4em !important;
    }

    h3 {
        font-size: 1.1em !important;
        line-height: 1.5em;
    }

    p {
        font-size: 1.1em !important;
        line-height: 1.5em;
    }
}

body {
    margin: 0;
    font-family: 'Open Sans', serif, -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c2c2f;
}

.is-clickable:hover {
    cursor: pointer;
}

.content {
    animation: fadeInAnimation ease-in 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: 10000 !important;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* navbar */
.nav-logo {
    width: 10em;
}

.footer-logo {
    width: 13em;
    margin-bottom: 2em;
}

.qr-code {
    width: 80%;
    text-align: left;
    margin-left: 0;
    min-width: 150px;
}

.footer-link a {
    font-size: 1.1em;
    margin-bottom: 0.5em;
}

.custom-nav {
    font-size: 0.8em;
    background-color: white;
    padding-right: 10%;
}

.nav-links {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.nav-tab {
    border-bottom: solid 4px #e7e7e7;
    color: #2c2c2f !important;
    text-decoration: none;
    padding-left: 2em !important;
    padding-right: 2em !important;
    font-weight: 600;
}

.nav-active {
    border-bottom: solid 4px #f07545;
}

.nav-dropdown {
    padding-left: 2em;
    padding-right: 2em;
    margin-bottom: 0;
    margin-top: 1em;
}

.dropdown-menu {
    margin-top: 0.5em !important;
}

.dropdown-menu a {
    font-size: 0.8em !important;
}

#basic-nav-dropdown {
    color: #2c2c2f !important;
}

.nav-btn {
    margin: auto 2em;
}

.dropdown-item:active {
    background-color: #f07545 !important;
}

/* landing page */
#landing {
    background: url(/static/media/landing.7faa78e5.png) no-repeat center center;
    background-size: cover;
    height: 56vh;
    margin-top: 0;
    width: auto;
    text-align: right;
    padding: 10vh 10vw;
}

#welcome-txt {
    text-align: left;
    font-size: 1.2em;
    margin-bottom: 0.2em;
}
#identity-txt {
    font-size: 1.2em;
    margin-top: 0.2em;
    margin-bottom: 0.8em;
    width: 100%;
}
.landing-boxes-small {
    height: 5vw;
}

.landing-boxes {
    height: 5vw;
}

#identity-div {
    text-align: right;
}

.landing-content-R {
    display: inline-block;
    text-align: right;
    padding-left: 0;
    left: 0;
    color: white;
}

.landing-btn {
    background: none;
    border: none;
    color: white;
    font-size: 1.2em;
    font-weight: 600;
    text-decoration: underline;
}

.landing-btn:hover {
    color: white;
    font-weight: 700;
    outline: none;
}

.landing-btn:active {
    outline: none;
}

.landing-btn:focus {
    outline: none;
}

#title {
    font-weight: 600;
    margin: 0;
}

/* metrics */
#metrics {
    background-color: #fff;
    text-align: center;
    padding-top: 4em;
    padding-bottom: 4em;
}
/* service landing section */
#service-landing {
    padding-left: 15%;
    padding-right: 15%;
    background-color: #f8f8f9;
    background-color: #fff;
    /*height: 16vh;*/
    display: flex;
    flex-direction: row;
}

.service-image {
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.65)),
        url(/static/media/warehouse-banner.033c9162.png) no-repeat center center;
}

.service-box {
    background-color: white;
    /*margin: -12vh 1em;*/
    width: 25vw;
    text-align: center;
    padding: 3em 1em 1em 1em;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
    margin-top: -4em;
}

.service-box p {
    color: #727574;
}

.trace {
    margin: auto;
    width: 20%;
    margin-bottom: 60px;
}


/* who we are */
#who-we-are {
    padding-top: 8em;
    padding-bottom: 8em;
    background: url(/static/media/who-we-are.dbd45040.png) no-repeat center center;
    background-size: cover;
    color: #ffffff;
    text-align: center;
}

#who-we-are h1 {
    font-weight: 700;
}

#who-we-are-text {
    width: 60vw;
    line-height: 1.2em;
    margin: 2em auto 3em auto;
}

/* service icons */
#services-icons-section {
    text-align: center;
    padding: 5% 20%;
    background-color: #f8f8f9;
}

#services-icons-section h2 {
    font-weight: 700;
}

#services-icons-section p {
    margin-bottom: 2em;
}

.services-icons {
    width: 5em;
}

.service-card {
    margin-top: 1em;
    margin-bottom: 1em;
}

.service-card p {
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

/* footer */
#footer {
    background-color: #f1f2f4;
    margin: 0;
    padding: 8em 10em 10em 10em;
    z-index: 0 !important;
}

#footer-content {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
}

#footer .col {
    margin-bottom: 2em;
}

#address-footer {
    margin-top: 2em;
    text-align: center;
    border-top: solid 1px #bfbfbf;
    padding-top: 2em;
}

#footer h3 {
    font-weight: 700;
    margin-bottom: 1em;
}

#footer a {
    color: #2c2c2f;
}

.footer-link {
    margin-bottom: 0.5em;
}

.contact-img {
    margin-top: 0.5em;
    height: 28px;
    margin-right: 0.5em;
}

#wechat {
    height: 2.8em;
    width: 2.8em;
    background: url(/static/media/wechat.bb0aabae.png) no-repeat;
    background-size: contain;
}

#wechat:hover {
    background: url(/static/media/wechat-g.71acd8ba.png) no-repeat;
    background-size: contain;
}

#whatsapp {
    height: 2.8em;
    width: 2.8em;
    background: url(/static/media/whatsapp.fb104b4e.png) no-repeat;
    background-size: contain;
}

#whatsapp:hover {
    background: url(/static/media/whatsapp-g.f095bceb.png) no-repeat;
    background-size: contain;
}

.inquiries-sub {
    font-weight: 700;
    margin-bottom: 0;
}

.contact-div {
    margin-right: 1em;
}

.contact-row {
    margin-top: 0.5em;
}

.connected-text {
    margin-bottom: 0.2em;
    font-weight: 600;
}

.contact-number {
    font-size: 1.6em;
    font-weight: bold;
    margin-bottom: 1em;
}

.contacts {
    width: 30%;
}
/* about page */
.mask {
    top: -8vh;
    z-index: 999 !important;
    background-color: rgba(0, 0, 0, 0.5);
    height: 120vh;
    width: 100vw;
    text-align: center;
    position: fixed;
}

.certificate-popup {
    width: 60vw;
    z-index: 999 !important;
}

.qr-popup {
    height: 60vh;
    z-index: 999 !important;
}
.certificate-div {
    margin-top: 20vh;
    display: flex;
    justify-content: center;
}

.popup-close {
    color: #2c2c2f;
    background-color: #e7e7e7;
    height: 1.1em;
    width: 1.1em;
    margin-left: -0.5em;
    margin-right: 0.5em;
    margin-top: -1em;
    font-weight: 600;
    text-align: left;
    font-size: 1.6em;
    font-weight: 100;
    line-height: 1em;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 0.5em;
}

.about-header {
    background: url(/static/media/about-banner.dc4f952c.png) no-repeat center center;
}

.about-content {
    background-color: #f8f8f9;
    padding-bottom: 10em;
    margin-top: 0;
}

.about-content h2 {
    font-weight: 600;
    margin-bottom: 1em;
}

.about-text p {
    width: 60vw;
    text-align: center;
    margin: 8vh auto;
}

.about-vision-image {
    width: 100%;
    background: url(/static/media/about-img-1.0477063b.jpg) no-repeat center center;
}

.about-img {
    margin-bottom: 4em;
    width: 100%;
    background: url(/static/media/about-img-2.bfb5fb07.jpg) no-repeat center center;
}

.about-vision-image h1 {
    font-weight: 700;
}

.about-img img {
    max-width: 100%;
}

.about-content-sub {
    margin-bottom: 6em;
}

/* philosophy section */
#phil-section {
    display: flex;
    background-color: #f1f2f4;
    padding-bottom: 2em;
}

#phil-box {
    margin: -15vh 10% 0 10%;
    padding: 2em;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.1);
}

#phil-title {
    font-weight: 600;
    border-bottom: solid 1px #e7e7e7;
    padding-bottom: 0.5em;
    width: 100%;
}

#phil-box .col {
    padding: 2em;
}

#phil-box .col:nth-child(1),
#phil-box .col:nth-child(2) {
    border-right: solid 1px #e7e7e7;
}

#phil-box h3 {
    margin-top: 2em;
    font-weight: 600;
}

/* commitments section */

#commitments {
    padding: 5%;
    background-color: #f1f2f4;
}

.certificate-row {
    margin-top: 4em;
    margin-left: auto;
    margin-right: auto;
    width: 60vw;
    height: 200px;
    overflow: hidden;
}
.certificate-img {
    width: 180px;
    object-fit: cover;
    height: auto;
}
.certificate-img:hover {
    width: 185px;
}

#certificates {
    padding: 5%;
    background-color: #f8f8f9;
}

#certificates h1 {
    font-weight: 600;
    margin-bottom: 1em;
}

#commitments h1 {
    font-weight: 600;
    margin-bottom: 1em;
}

.value {
    color: #1dbc98;
    font-weight: 700;
    margin: 1em 2em;
    padding: 1em 5em;
    background-color: #ffffff;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
    width: 25vw;
}

/* get quote box */
.service-page-box {
    background-color: white;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
    width: 400px;
    min-width: 70%;
    margin: 0 auto;
    max-width: 100%;
}

.service-box-active p {
    color: #1dbc98;
    font-weight: 700;
}

.service-box-active a:hover {
    text-decoration: none !important;
}

#service-page-nav a {
    color: #2c2c2f;
}

#service-page-nav p:hover {
    background-color: #f8f8f9;
}

#service-page-nav a:hover {
    text-decoration: none;
}

.service-page-box h3 {
    text-align: center;
    font-weight: 600;
    background-color: #1dbc98;
    padding: 1em;
    margin-bottom: 2px;
    color: #ffffff;
}

.service-page-content {
    padding: 0.5em 1em 1em 1em;
}

.service-page-btn {
    display: block;
    margin-top: 1em;
}

.service-page-nav-item {
    padding: 1em;
    margin: 0 1em;
}

li p {
    margin: 0.5em !important;
}

.service-page-nav-item:nth-child(1),
.service-page-nav-item:nth-child(2) {
    border-bottom: solid 1px #e7e7e7;
}

/* shipping service page */
.service-page {
    background-color: #f8f8f9;
    padding: 5%;
}

.service-R {
    width: 70%;
    padding: 0 4em;
}

.service-R h2 {
    font-weight: 600;
}

.service-R .row {
    margin-top: 2em;
}

.service-R li {
    margin-top: 1em;
    font-size: 1.1em;
}

.service-R .col:nth-child(1) {
    margin-right: 4em;
}

.service-L {
    width: 30%;
}

.service-R h3 {
    font-weight: 600;
    margin-bottom: 1em;
}

.shipping-header {
    background: url(/static/media/shipping-banner.2ac7d9d5.png) no-repeat center center;
}

.warehouse-header {
    background: url(/static/media/warehouse-banner.033c9162.png) no-repeat center center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.warehouse-page-img {
    width: 20em;
}

.ocean-header {
    background: url(/static/media/ocean-banner.efd9b226.png) no-repeat center center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
}

.airfreight-header {
    background: url(/static/media/airfreight-banner.dedc35ad.png) no-repeat center center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.header-img {
    height: 30vh;
    padding-top: 10vh;
    background-size: cover;
    justify-content: center;
}

.header {
    color: #ffffff;
    font-weight: 700;
}

/* form submit */

#form-submit-pg {
    background-color: #f8f8f9;
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 4em;
    height: 50vh;
}

.theme-btn {
    text-align: center;
    font-size: 1.1em;
    outline: none;
    background-size: 100% 200%;
    background-image: linear-gradient(to top, #f07545 50%, #1dbc98 50%);
    transition: background-position 0.2s;
    padding: 0.8em 2em;
    border: none;
    color: white;
    font-weight: 600;
    border-radius: 0.2em;
}

.theme-btn:hover {
    color: white;
    font-weight: 700;
    text-decoration: none;
    background-position: 0 100%;
}

/* ocean shipping */
.sub-page-icon-text {
    margin-top: 2em;
    font-weight: 600;
}

/* form toggle */
.form-toggle {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 3%;
    text-align: right;
    z-index: 100000;
}

@keyframes slide-form {
    from {
        max-height: 0;
        overflow: hidden;
    }
    to {
        max-height: 1000px;
        overflow: hidden;
    }
}

.form-toggle form {
    max-height: 70vh;
    overflow: scroll;
    background-color: #ffffff;
    padding: 2em;
    margin-bottom: 2em;
    border-radius: 4px;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
    width: 1100px;
    max-width: 80vw;
    text-align: left;
    animation-name: slide-form;
    animation-duration: 0.2s;
}

.form-toggle form p {
    margin-bottom: 2em;
}
.form-toggle-chat {
    border-radius: 50%;
    background-color: #1dbc98;
    width: 4.2em;
    height: 4.2em;
    border: none;
    outline: none;
    text-align: center;
    vertical-align: middle;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
}

.form-toggle-chat:focus {
    border: none;
    outline: none;
}

.form-toggle-icon {
    width: 2.1em;
    padding-top: 0.3em;
}

.how-it-works {
    padding-top: 1em;
    padding-bottom: 2em;
}

.how-it-works h2 {
    font-weight: 700;
}

.how-it-works-numbers {
    color: #1dbc98;
}
.how-it-works h3 {
    font-weight: 700;
}

/* location page */
.location {
    padding: 5% 10%;
    text-align: center;
    background-color: #f8f8f9;
}
.location-squares {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}
.location-squares h1 {
    font-weight: 700;
}

.img-location {
    text-align: center;
}

.location-title {
    font-weight: 700;
}

.location-image {
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.65)),
        url(/static/media/shanghai.32911a43.png) no-repeat center center;
}

.location-squares {
    margin-top: 4em;
}

.location-text {
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
    text-align: left;
    width: 500px;
    max-width: 40vw;
}

.location-squares-img {
    width: 500px;
    max-width: 40vw;
    object-fit: cover;
    height: 300px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.business-header {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.65)),
        url(/static/media/business-solutions.664f18e5.png) no-repeat center center;
}

.customs-header {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.65)),
        url(/static/media/customs.4ff728c5.png) no-repeat center center;
}

.ecommerce-header {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.65)),
        url(/static/media/ecommerce.57bfc951.png) no-repeat center center;
}

#contact-map-section {
    height: 700 !important;
    position: relative;
}

.contact-card {
    margin-top: 2em;
    margin-left: 2em;
    position: absolute;
    z-index: 10000;
    background-color: #ffffff;
    padding: 2em 4em 1em 4em;
    border-radius: 4px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.contact-card h3 {
    font-weight: 700;
    margin-bottom: 1em;
}

.contact-col {
    height: 500;
    top: 0;
}

.map {
    position: relative !important;
}

/* ipad */
@media (min-width: 767px) and (max-width: 1050px) {
    #title {
        font-size: 40px !important;
    }

    h1 {
        font-size: 30px !important;
    }

    h2 {
        font-size: 24px !important;
    }

    h3 {
        font-size: 18px !important;
    }

    p {
        font-size: 16px !important;
        line-height: 24px;
    }

    label {
        font-size: 14px;
    }

    .custom-nav {
        font-size: 16px !important;
        background-color: white;
        padding: 0.5em !important;
        padding-right: 10% !important;
    }

    .nav-active {
        border: none !important;
    }

    .nav-tab {
        border-bottom: none !important;
        padding-left: 1em !important;
    }

    .dropdown-item {
        font-size: 14px !important;
    }

    .footer-logo {
        width: 100% !important;
    }

    #landing {
        max-height: 340px !important;
        padding-top: 10%;
    }

    #welcome-txt {
        text-align: left;
        font-size: 18px;
        margin-bottom: 0.2em;
    }
    #identity-txt {
        font-size: 18px;
        margin-top: 0.2em;
        margin-bottom: 0.8em;
        width: 100%;
    }

    .landing-btn {
        font-size: 18px;
    }

    #service-landing {
        /*max-height: 150px !important;*/
        padding-left: 5%;
        padding-right: 5%;
        flex-wrap: wrap;
        padding-top: 2em;
        padding-bottom: 2em;
    }

    .service-box {
        /* margin: -3em 0.5em 0 0.5em;*/
        padding: 1.5em 0.5em 0.5em 0.5em;
        width: 30%;
        margin: 0.5em;
    }

    .services-icons {
        width: 4em;
    }
    .landing-boxes-small {
        height: 5vw !important;
    }

    .landing-boxes {
        height: 6vw !important;
    }

    input {
        font-size: 14px !important;
    }

    .theme-btn {
        font-size: 16px !important;
    }

    /* footer */
    .contact-number {
        font-size: 24px;
    }

    #footer {
        background-color: #f1f2f4;
        margin: 0;
        padding: 8em 2em 10em 2em;
        z-index: 0 !important;
    }

    #footer-content {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .footer-link a {
        font-size: 16px;
    }

    /* about pg */
    .header-img {
        height: 200px !important;
        padding-top: 80px !important;
    }

    .about-content-sub {
        margin-bottom: 2em !important;
    }

    .about-text-R {
        padding-left: 2em !important;
    }

    .about-content {
        padding-bottom: 6em !important;
    }

    .contact-img {
        height: 1.2em;
        margin-top: 0;
    }
    /* about us philosophy */
    #phil-box .col {
        padding: 1em !important;
    }

    #phil-box {
        margin-top: -100px !important;
    }

    #phil-box h3 {
        margin-top: 1em !important;
    }

    .value {
        padding: 1em 3em !important;
    }

    /* service page */
    .service-L {
        width: 35% !important;
    }

    .service-R {
        width: 65% !important;
        padding: 0 !important;
    }

    .service-page-box {
        margin: 0 !important;
        width: 250px !important;
        max-width: 90%;
        min-width: 10% !important;
    }

    /*location page*/
    .location-text {
        margin-left: auto;
        margin-right: auto;
        padding-top: 2em;
        text-align: left;
        max-width: 40vw;
    }

    .location {
        padding: 5%;
    }
    .location-squares-img {
        max-width: 40vw;
        height: 20vh;
        box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
    }

    .service-R li {
        margin-top: 1em;
        font-size: 16px;
    }

    .form-toggle-chat {
        width: 70px;
        height: 70px;
    }

    .form-toggle-icon {
        width: 36px;
    }
}

/* phone */
@media (max-width: 767px) {
    .navbar-toggler-icon {
        width: 0.8em;
        height: 1em;
    }
    .nav-logo {
        width: 6em !important;
    }

    .navbar-toggler {
        padding: 0.2em 0.5em !important;
    }

    .nav-btn {
        width: 90% !important;
        margin-top: 1em !important;
    }
    .custom-nav {
        height: auto;
    }
    #title {
        font-size: 1.6em !important;
        line-height: 1.6em;
    }

    h1 {
        font-size: 1.1em !important;
    }

    h2 {
        font-size: 1.1em !important;
    }

    h3 {
        font-size: 1em !important;
    }

    p {
        font-size: 0.8em !important;
        line-height: 1.2em !important;
    }

    .nav-active {
        border: none !important;
    }

    .nav-tab {
        border-bottom: none !important;
        padding-left: 1em !important;
    }
    .navbar-nav a {
        font-size: 0.8em;
        margin-left: 0;
    }

    .dropdown-menu a {
        font-size: 0.8em !important;
    }

    /* landing phone */
    #landing {
        padding: 15% 1em;
    }

    .landing-content-R {
        width: 100% !important;
        margin-left: 0 !important;
        text-align: center !important;
    }

    .services-icons {
        width: 3em;
        margin-bottom: 0.5em;
    }

    .service-card {
        width: 80%;
        margin: auto;
    }

    #metrics {
        padding: 2em 2em;
        background-color: #1dbc98;
        color: #fff;
    }

    .certificate-row {
        height: auto;
    }

    .certificate-img {
        width: 8em;
    }
    .certificate-img:hover {
        width: 8em;
    }
    .certificate-popup {
        width: 80vw;
    }

    .popup-close {
        height: 25px;
        width: 25px;
        margin-left: -0.3em;
        margin-right: 0.3em;
        margin-top: -0.8em;
        font-weight: 600;
        text-align: left;
        font-size: 20px;
        line-height: 20px;
        border-radius: 50%;
        padding: 1px 7px;
        margin-bottom: 0.5em;
    }

    .qr-popup {
        max-width: 80%;
        height: auto;
    }
    #welcome-txt {
        text-align: center;
    }

    #identity-div {
        text-align: center !important;
    }

    #identity-txt {
        font-size: 0.8em !important;
        margin-bottom: 0.5em !important;
    }

    #landing {
        height: 250px !important;
    }

    #welcome-txt {
        font-size: 1em;
        margin-bottom: 0.2em;
    }
    #identity-txt {
        font-size: 1em;
        margin-top: 0.2em;
        margin-bottom: 0.8em;
        width: 100%;
    }

    .landing-btn {
        font-size: 0.8em;
    }
    .service-card {
        width: 48vw;
    }

    /* services phone */
    .service-box {
        width: 48vw !important;
        margin: 0.1em !important;
        box-shadow: none !important;
    }

    .service-box h3 {
        font-size: 0.8em !important;
        margin-bottom: 0.5em !important;
    }

    #service-landing {
        display: flex;
        flex-wrap: wrap;

        /* height: 160px !important;*/
    }

    .landing-boxes {
        height: 50px !important;
    }

    .landing-boxes-small {
        height: 30px !important;
    }

    .form-col,
    .form-group {
        width: 100% !important;
        margin: 0 !important;
    }

    label {
        font-size: 0.8em;
    }

    input {
        font-size: 0.8em !important;
    }

    /* footer phone */
    #footer {
        position: relative;
        padding: 1em 2em;
        z-index: 0;
    }
    .contact-number {
        font-size: 1.3em;
    }
    #whatsapp,
    #wechat {
        margin-top: 0.5em;
        width: 26px;
    }

    #who-we-are-text {
        width: 80vw;
    }

    #services-icons-section {
        padding: 4em 10%;
    }

    #footer h3 {
        margin-top: 2em;
    }

    .footer-logo {
        width: 160px !important;
        margin-top: 2em;
        margin-bottom: 1em !important;
    }

    .footer-link a {
        margin: 0 !important;
        font-size: 0.8em;
    }

    .contacts {
        margin-top: 0.5em;
        margin-left: 1em;
        width: 100% !important;
    }

    .contact-img {
        height: 1.1em;
        margin-top: 0;
    }

    .theme-btn {
        font-size: 0.7em !important;
    }

    /* about pg phone */
    .header-img {
        height: 100px !important;
        padding-top: 30px !important;
    }

    .about-content-sub {
        margin-bottom: 2em !important;
        display: block;
    }

    .about-img {
        width: 100% !important;
    }

    .about-content {
        padding-bottom: 1em !important;
    }

    /* about us philosophy */
    #phil-box .col {
        padding: 1em !important;
        font-size: 0.8em;
    }

    .value {
        font-size: 0.8em !important;
    }
    #phil-box {
        margin: 0 !important;
        margin-top: -1em !important;
    }

    #phil-box h3 {
        margin-top: 1em !important;
    }

    #phil-section {
        padding: 0 !important;
    }

    .value {
        margin: 1em 0.2em !important;
        width: 10em !important;
        padding: 0.5em 2em !important;
        text-align: center !important;
    }

    .about-text p {
        width: 80vw;
        text-align: center;
        margin: 8vh auto;
    }

    /* service page phone */
    .service-L {
        display: none;
    }

    .service-R {
        margin-top: 2em;
        width: 100% !important;
        padding: 0 1em !important;
    }

    .service-R .row {
        margin-top: 0 !important;
    }

    .dropdown-item {
        font-size: 0.8em !important;
    }

    /* form toggle */
    .form-toggle form {
        max-height: 520px;
        max-width: 85vw;
        padding: 1em 0.5em;
        margin: 1em 0.5em;
        height: 75vh;
        overflow: scroll;
    }
    .form-toggle form button {
        margin-top: 1em;
    }
    .form-toggle-chat {
        width: 3.2em;
        height: 3.2em;
    }
    .form-toggle-icon {
        width: 1.6em;
        padding-top: 0.2em;
    }

    /*location page*/
    .location-text {
        max-width: 80vw;
    }

    .location-title {
        padding-top: 1em !important;
    }

    .location {
        padding: 5%;
    }

    .location .col-md {
        margin-bottom: 1em;
    }
    .location-squares-img {
        max-width: 80vw;
        height: 25vh;
        box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
    }

    .location-squares {
        margin-top: 2em;
    }
}

