/* ipad */
@media (min-width: 767px) and (max-width: 1050px) {
    #title {
        font-size: 40px !important;
    }

    h1 {
        font-size: 30px !important;
    }

    h2 {
        font-size: 24px !important;
    }

    h3 {
        font-size: 18px !important;
    }

    p {
        font-size: 16px !important;
        line-height: 24px;
    }

    label {
        font-size: 14px;
    }

    .custom-nav {
        font-size: 16px !important;
        background-color: white;
        padding: 0.5em !important;
        padding-right: 10% !important;
    }

    .nav-active {
        border: none !important;
    }

    .nav-tab {
        border-bottom: none !important;
        padding-left: 1em !important;
    }

    .dropdown-item {
        font-size: 14px !important;
    }

    .footer-logo {
        width: 100% !important;
    }

    #landing {
        max-height: 340px !important;
        padding-top: 10%;
    }

    #welcome-txt {
        text-align: left;
        font-size: 18px;
        margin-bottom: 0.2em;
    }
    #identity-txt {
        font-size: 18px;
        margin-top: 0.2em;
        margin-bottom: 0.8em;
        width: 100%;
    }

    .landing-btn {
        font-size: 18px;
    }

    #service-landing {
        /*max-height: 150px !important;*/
        padding-left: 5%;
        padding-right: 5%;
        flex-wrap: wrap;
        padding-top: 2em;
        padding-bottom: 2em;
    }

    .service-box {
        /* margin: -3em 0.5em 0 0.5em;*/
        padding: 1.5em 0.5em 0.5em 0.5em;
        width: 30%;
        margin: 0.5em;
    }

    .services-icons {
        width: 4em;
    }
    .landing-boxes-small {
        height: 5vw !important;
    }

    .landing-boxes {
        height: 6vw !important;
    }

    input {
        font-size: 14px !important;
    }

    .theme-btn {
        font-size: 16px !important;
    }

    /* footer */
    .contact-number {
        font-size: 24px;
    }

    #footer {
        background-color: #f1f2f4;
        margin: 0;
        padding: 8em 2em 10em 2em;
        z-index: 0 !important;
    }

    #footer-content {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .footer-link a {
        font-size: 16px;
    }

    /* about pg */
    .header-img {
        height: 200px !important;
        padding-top: 80px !important;
    }

    .about-content-sub {
        margin-bottom: 2em !important;
    }

    .about-text-R {
        padding-left: 2em !important;
    }

    .about-content {
        padding-bottom: 6em !important;
    }

    .contact-img {
        height: 1.2em;
        margin-top: 0;
    }
    /* about us philosophy */
    #phil-box .col {
        padding: 1em !important;
    }

    #phil-box {
        margin-top: -100px !important;
    }

    #phil-box h3 {
        margin-top: 1em !important;
    }

    .value {
        padding: 1em 3em !important;
    }

    /* service page */
    .service-L {
        width: 35% !important;
    }

    .service-R {
        width: 65% !important;
        padding: 0 !important;
    }

    .service-page-box {
        margin: 0 !important;
        width: 250px !important;
        max-width: 90%;
        min-width: 10% !important;
    }

    /*location page*/
    .location-text {
        margin-left: auto;
        margin-right: auto;
        padding-top: 2em;
        text-align: left;
        max-width: 40vw;
    }

    .location {
        padding: 5%;
    }
    .location-squares-img {
        max-width: 40vw;
        height: 20vh;
        box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
    }

    .service-R li {
        margin-top: 1em;
        font-size: 16px;
    }

    .form-toggle-chat {
        width: 70px;
        height: 70px;
    }

    .form-toggle-icon {
        width: 36px;
    }
}
