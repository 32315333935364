@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap');

@media (min-width: 1050px) {
    #title {
        font-size: 2.5em !important;
    }

    h1 {
        font-size: 1.8em;
    }

    h2 {
        font-size: 1.4em !important;
    }

    h3 {
        font-size: 1.1em !important;
        line-height: 1.5em;
    }

    p {
        font-size: 1.1em !important;
        line-height: 1.5em;
    }
}

body {
    margin: 0;
    font-family: 'Open Sans', serif, -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c2c2f;
}

.is-clickable:hover {
    cursor: pointer;
}

.content {
    animation: fadeInAnimation ease-in 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: 10000 !important;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* navbar */
.nav-logo {
    width: 10em;
}

.footer-logo {
    width: 13em;
    margin-bottom: 2em;
}

.qr-code {
    width: 80%;
    text-align: left;
    margin-left: 0;
    min-width: 150px;
}

.footer-link a {
    font-size: 1.1em;
    margin-bottom: 0.5em;
}

.custom-nav {
    font-size: 0.8em;
    background-color: white;
    padding-right: 10%;
}

.nav-links {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.nav-tab {
    border-bottom: solid 4px #e7e7e7;
    color: #2c2c2f !important;
    text-decoration: none;
    padding-left: 2em !important;
    padding-right: 2em !important;
    font-weight: 600;
}

.nav-active {
    border-bottom: solid 4px #f07545;
}

.nav-dropdown {
    padding-left: 2em;
    padding-right: 2em;
    margin-bottom: 0;
    margin-top: 1em;
}

.dropdown-menu {
    margin-top: 0.5em !important;
}

.dropdown-menu a {
    font-size: 0.8em !important;
}

#basic-nav-dropdown {
    color: #2c2c2f !important;
}

.nav-btn {
    margin: auto 2em;
}

.dropdown-item:active {
    background-color: #f07545 !important;
}

/* landing page */
#landing {
    background: url('./images/landing.png') no-repeat center center;
    background-size: cover;
    height: 56vh;
    margin-top: 0;
    width: auto;
    text-align: right;
    padding: 10vh 10vw;
}

#welcome-txt {
    text-align: left;
    font-size: 1.2em;
    margin-bottom: 0.2em;
}
#identity-txt {
    font-size: 1.2em;
    margin-top: 0.2em;
    margin-bottom: 0.8em;
    width: 100%;
}
.landing-boxes-small {
    height: 5vw;
}

.landing-boxes {
    height: 5vw;
}

#identity-div {
    text-align: right;
}

.landing-content-R {
    display: inline-block;
    text-align: right;
    padding-left: 0;
    left: 0;
    color: white;
}

.landing-btn {
    background: none;
    border: none;
    color: white;
    font-size: 1.2em;
    font-weight: 600;
    text-decoration: underline;
}

.landing-btn:hover {
    color: white;
    font-weight: 700;
    outline: none;
}

.landing-btn:active {
    outline: none;
}

.landing-btn:focus {
    outline: none;
}

#title {
    font-weight: 600;
    margin: 0;
}

/* metrics */
#metrics {
    background-color: #fff;
    text-align: center;
    padding-top: 4em;
    padding-bottom: 4em;
}
/* service landing section */
#service-landing {
    padding-left: 15%;
    padding-right: 15%;
    background-color: #f8f8f9;
    background-color: #fff;
    /*height: 16vh;*/
    display: flex;
    flex-direction: row;
}

.service-image {
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.65)),
        url('./images/warehouse-banner.png') no-repeat center center;
}

.service-box {
    background-color: white;
    /*margin: -12vh 1em;*/
    width: 25vw;
    text-align: center;
    padding: 3em 1em 1em 1em;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
    margin-top: -4em;
}

.service-box p {
    color: #727574;
}

.trace {
    margin: auto;
    width: 20%;
    margin-bottom: 60px;
}


/* who we are */
#who-we-are {
    padding-top: 8em;
    padding-bottom: 8em;
    background: url('./images/who-we-are.png') no-repeat center center;
    background-size: cover;
    color: #ffffff;
    text-align: center;
}

#who-we-are h1 {
    font-weight: 700;
}

#who-we-are-text {
    width: 60vw;
    line-height: 1.2em;
    margin: 2em auto 3em auto;
}

/* service icons */
#services-icons-section {
    text-align: center;
    padding: 5% 20%;
    background-color: #f8f8f9;
}

#services-icons-section h2 {
    font-weight: 700;
}

#services-icons-section p {
    margin-bottom: 2em;
}

.services-icons {
    width: 5em;
}

.service-card {
    margin-top: 1em;
    margin-bottom: 1em;
}

.service-card p {
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

/* footer */
#footer {
    background-color: #f1f2f4;
    margin: 0;
    padding: 8em 10em 10em 10em;
    z-index: 0 !important;
}

#footer-content {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
}

#footer .col {
    margin-bottom: 2em;
}

#address-footer {
    margin-top: 2em;
    text-align: center;
    border-top: solid 1px #bfbfbf;
    padding-top: 2em;
}

#footer h3 {
    font-weight: 700;
    margin-bottom: 1em;
}

#footer a {
    color: #2c2c2f;
}

.footer-link {
    margin-bottom: 0.5em;
}

.contact-img {
    margin-top: 0.5em;
    height: 28px;
    margin-right: 0.5em;
}

#wechat {
    height: 2.8em;
    width: 2.8em;
    background: url('./images/wechat.png') no-repeat;
    background-size: contain;
}

#wechat:hover {
    background: url('./images/wechat-g.png') no-repeat;
    background-size: contain;
}

#whatsapp {
    height: 2.8em;
    width: 2.8em;
    background: url('./images/whatsapp.png') no-repeat;
    background-size: contain;
}

#whatsapp:hover {
    background: url('./images/whatsapp-g.png') no-repeat;
    background-size: contain;
}

.inquiries-sub {
    font-weight: 700;
    margin-bottom: 0;
}

.contact-div {
    margin-right: 1em;
}

.contact-row {
    margin-top: 0.5em;
}

.connected-text {
    margin-bottom: 0.2em;
    font-weight: 600;
}

.contact-number {
    font-size: 1.6em;
    font-weight: bold;
    margin-bottom: 1em;
}

.contacts {
    width: 30%;
}
/* about page */
.mask {
    top: -8vh;
    z-index: 999 !important;
    background-color: rgba(0, 0, 0, 0.5);
    height: 120vh;
    width: 100vw;
    text-align: center;
    position: fixed;
}

.certificate-popup {
    width: 60vw;
    z-index: 999 !important;
}

.qr-popup {
    height: 60vh;
    z-index: 999 !important;
}
.certificate-div {
    margin-top: 20vh;
    display: flex;
    justify-content: center;
}

.popup-close {
    color: #2c2c2f;
    background-color: #e7e7e7;
    height: 1.1em;
    width: 1.1em;
    margin-left: -0.5em;
    margin-right: 0.5em;
    margin-top: -1em;
    font-weight: 600;
    text-align: left;
    font-size: 1.6em;
    font-weight: 100;
    line-height: 1em;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 0.5em;
}

.about-header {
    background: url('./images/about-banner.png') no-repeat center center;
}

.about-content {
    background-color: #f8f8f9;
    padding-bottom: 10em;
    margin-top: 0;
}

.about-content h2 {
    font-weight: 600;
    margin-bottom: 1em;
}

.about-text p {
    width: 60vw;
    text-align: center;
    margin: 8vh auto;
}

.about-vision-image {
    width: 100%;
    background: url('./images/about-img-1.jpg') no-repeat center center;
}

.about-img {
    margin-bottom: 4em;
    width: 100%;
    background: url('./images/about-img-2.jpg') no-repeat center center;
}

.about-vision-image h1 {
    font-weight: 700;
}

.about-img img {
    max-width: 100%;
}

.about-content-sub {
    margin-bottom: 6em;
}

/* philosophy section */
#phil-section {
    display: flex;
    background-color: #f1f2f4;
    padding-bottom: 2em;
}

#phil-box {
    margin: -15vh 10% 0 10%;
    padding: 2em;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.1);
}

#phil-title {
    font-weight: 600;
    border-bottom: solid 1px #e7e7e7;
    padding-bottom: 0.5em;
    width: 100%;
}

#phil-box .col {
    padding: 2em;
}

#phil-box .col:nth-child(1),
#phil-box .col:nth-child(2) {
    border-right: solid 1px #e7e7e7;
}

#phil-box h3 {
    margin-top: 2em;
    font-weight: 600;
}

/* commitments section */

#commitments {
    padding: 5%;
    background-color: #f1f2f4;
}

.certificate-row {
    margin-top: 4em;
    margin-left: auto;
    margin-right: auto;
    width: 60vw;
    height: 200px;
    overflow: hidden;
}
.certificate-img {
    width: 180px;
    object-fit: cover;
    height: auto;
}
.certificate-img:hover {
    width: 185px;
}

#certificates {
    padding: 5%;
    background-color: #f8f8f9;
}

#certificates h1 {
    font-weight: 600;
    margin-bottom: 1em;
}

#commitments h1 {
    font-weight: 600;
    margin-bottom: 1em;
}

.value {
    color: #1dbc98;
    font-weight: 700;
    margin: 1em 2em;
    padding: 1em 5em;
    background-color: #ffffff;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
    width: 25vw;
}

/* get quote box */
.service-page-box {
    background-color: white;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
    width: 400px;
    min-width: 70%;
    margin: 0 auto;
    max-width: 100%;
}

.service-box-active p {
    color: #1dbc98;
    font-weight: 700;
}

.service-box-active a:hover {
    text-decoration: none !important;
}

#service-page-nav a {
    color: #2c2c2f;
}

#service-page-nav p:hover {
    background-color: #f8f8f9;
}

#service-page-nav a:hover {
    text-decoration: none;
}

.service-page-box h3 {
    text-align: center;
    font-weight: 600;
    background-color: #1dbc98;
    padding: 1em;
    margin-bottom: 2px;
    color: #ffffff;
}

.service-page-content {
    padding: 0.5em 1em 1em 1em;
}

.service-page-btn {
    display: block;
    margin-top: 1em;
}

.service-page-nav-item {
    padding: 1em;
    margin: 0 1em;
}

li p {
    margin: 0.5em !important;
}

.service-page-nav-item:nth-child(1),
.service-page-nav-item:nth-child(2) {
    border-bottom: solid 1px #e7e7e7;
}

/* shipping service page */
.service-page {
    background-color: #f8f8f9;
    padding: 5%;
}

.service-R {
    width: 70%;
    padding: 0 4em;
}

.service-R h2 {
    font-weight: 600;
}

.service-R .row {
    margin-top: 2em;
}

.service-R li {
    margin-top: 1em;
    font-size: 1.1em;
}

.service-R .col:nth-child(1) {
    margin-right: 4em;
}

.service-L {
    width: 30%;
}

.service-R h3 {
    font-weight: 600;
    margin-bottom: 1em;
}

.shipping-header {
    background: url('./images/shipping-banner.png') no-repeat center center;
}

.warehouse-header {
    background: url('./images/warehouse-banner.png') no-repeat center center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.warehouse-page-img {
    width: 20em;
}

.ocean-header {
    background: url('./images/ocean-banner.png') no-repeat center center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
}

.airfreight-header {
    background: url('./images/airfreight-banner.png') no-repeat center center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.header-img {
    height: 30vh;
    padding-top: 10vh;
    background-size: cover;
    justify-content: center;
}

.header {
    color: #ffffff;
    font-weight: 700;
}

/* form submit */

#form-submit-pg {
    background-color: #f8f8f9;
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 4em;
    height: 50vh;
}

.theme-btn {
    text-align: center;
    font-size: 1.1em;
    outline: none;
    background-size: 100% 200%;
    background-image: linear-gradient(to top, #f07545 50%, #1dbc98 50%);
    transition: background-position 0.2s;
    padding: 0.8em 2em;
    border: none;
    color: white;
    font-weight: 600;
    border-radius: 0.2em;
}

.theme-btn:hover {
    color: white;
    font-weight: 700;
    text-decoration: none;
    background-position: 0 100%;
}

/* ocean shipping */
.sub-page-icon-text {
    margin-top: 2em;
    font-weight: 600;
}

/* form toggle */
.form-toggle {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 3%;
    text-align: right;
    z-index: 100000;
}

@keyframes slide-form {
    from {
        max-height: 0;
        overflow: hidden;
    }
    to {
        max-height: 1000px;
        overflow: hidden;
    }
}

.form-toggle form {
    max-height: 70vh;
    overflow: scroll;
    background-color: #ffffff;
    padding: 2em;
    margin-bottom: 2em;
    border-radius: 4px;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
    width: 1100px;
    max-width: 80vw;
    text-align: left;
    animation-name: slide-form;
    animation-duration: 0.2s;
}

.form-toggle form p {
    margin-bottom: 2em;
}
.form-toggle-chat {
    border-radius: 50%;
    background-color: #1dbc98;
    width: 4.2em;
    height: 4.2em;
    border: none;
    outline: none;
    text-align: center;
    vertical-align: middle;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.2);
}

.form-toggle-chat:focus {
    border: none;
    outline: none;
}

.form-toggle-icon {
    width: 2.1em;
    padding-top: 0.3em;
}

.how-it-works {
    padding-top: 1em;
    padding-bottom: 2em;
}

.how-it-works h2 {
    font-weight: 700;
}

.how-it-works-numbers {
    color: #1dbc98;
}
.how-it-works h3 {
    font-weight: 700;
}

/* location page */
.location {
    padding: 5% 10%;
    text-align: center;
    background-color: #f8f8f9;
}
.location-squares {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}
.location-squares h1 {
    font-weight: 700;
}

.img-location {
    text-align: center;
}

.location-title {
    font-weight: 700;
}

.location-image {
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.65)),
        url('./images/shanghai.png') no-repeat center center;
}

.location-squares {
    margin-top: 4em;
}

.location-text {
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
    text-align: left;
    width: 500px;
    max-width: 40vw;
}

.location-squares-img {
    width: 500px;
    max-width: 40vw;
    object-fit: cover;
    height: 300px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.business-header {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.65)),
        url('./images/business-solutions.png') no-repeat center center;
}

.customs-header {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.65)),
        url('./images/customs.png') no-repeat center center;
}

.ecommerce-header {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.65)),
        url('./images/ecommerce.png') no-repeat center center;
}

#contact-map-section {
    height: 700 !important;
    position: relative;
}

.contact-card {
    margin-top: 2em;
    margin-left: 2em;
    position: absolute;
    z-index: 10000;
    background-color: #ffffff;
    padding: 2em 4em 1em 4em;
    border-radius: 4px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.contact-card h3 {
    font-weight: 700;
    margin-bottom: 1em;
}

.contact-col {
    height: 500;
    top: 0;
}

.map {
    position: relative !important;
}
