.loading-screen {
    text-align: center;
    width: 100vw;
    height: 100vh;
}

.loading-screen h1 {
    margin-top: 30vh;
    font-weight: 700;
    font-size: 3em !important;
    font-family: 'Arial' !important;
}

.loader {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #1dbc98;
    border-radius: 50%;
    width: 8em;
    height: 8em;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
