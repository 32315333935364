/* phone */
@media (max-width: 767px) {
    .navbar-toggler-icon {
        width: 0.8em;
        height: 1em;
    }
    .nav-logo {
        width: 6em !important;
    }

    .navbar-toggler {
        padding: 0.2em 0.5em !important;
    }

    .nav-btn {
        width: 90% !important;
        margin-top: 1em !important;
    }
    .custom-nav {
        height: auto;
    }
    #title {
        font-size: 1.6em !important;
        line-height: 1.6em;
    }

    h1 {
        font-size: 1.1em !important;
    }

    h2 {
        font-size: 1.1em !important;
    }

    h3 {
        font-size: 1em !important;
    }

    p {
        font-size: 0.8em !important;
        line-height: 1.2em !important;
    }

    .nav-active {
        border: none !important;
    }

    .nav-tab {
        border-bottom: none !important;
        padding-left: 1em !important;
    }
    .navbar-nav a {
        font-size: 0.8em;
        margin-left: 0;
    }

    .dropdown-menu a {
        font-size: 0.8em !important;
    }

    /* landing phone */
    #landing {
        padding: 15% 1em;
    }

    .landing-content-R {
        width: 100% !important;
        margin-left: 0 !important;
        text-align: center !important;
    }

    .services-icons {
        width: 3em;
        margin-bottom: 0.5em;
    }

    .service-card {
        width: 80%;
        margin: auto;
    }

    #metrics {
        padding: 2em 2em;
        background-color: #1dbc98;
        color: #fff;
    }

    .certificate-row {
        height: auto;
    }

    .certificate-img {
        width: 8em;
    }
    .certificate-img:hover {
        width: 8em;
    }
    .certificate-popup {
        width: 80vw;
    }

    .popup-close {
        height: 25px;
        width: 25px;
        margin-left: -0.3em;
        margin-right: 0.3em;
        margin-top: -0.8em;
        font-weight: 600;
        text-align: left;
        font-size: 20px;
        line-height: 20px;
        border-radius: 50%;
        padding: 1px 7px;
        margin-bottom: 0.5em;
    }

    .qr-popup {
        max-width: 80%;
        height: auto;
    }
    #welcome-txt {
        text-align: center;
    }

    #identity-div {
        text-align: center !important;
    }

    #identity-txt {
        font-size: 0.8em !important;
        margin-bottom: 0.5em !important;
    }

    #landing {
        height: 250px !important;
    }

    #welcome-txt {
        font-size: 1em;
        margin-bottom: 0.2em;
    }
    #identity-txt {
        font-size: 1em;
        margin-top: 0.2em;
        margin-bottom: 0.8em;
        width: 100%;
    }

    .landing-btn {
        font-size: 0.8em;
    }
    .service-card {
        width: 48vw;
    }

    /* services phone */
    .service-box {
        width: 48vw !important;
        margin: 0.1em !important;
        box-shadow: none !important;
    }

    .service-box h3 {
        font-size: 0.8em !important;
        margin-bottom: 0.5em !important;
    }

    #service-landing {
        display: flex;
        flex-wrap: wrap;

        /* height: 160px !important;*/
    }

    .landing-boxes {
        height: 50px !important;
    }

    .landing-boxes-small {
        height: 30px !important;
    }

    .form-col,
    .form-group {
        width: 100% !important;
        margin: 0 !important;
    }

    label {
        font-size: 0.8em;
    }

    input {
        font-size: 0.8em !important;
    }

    /* footer phone */
    #footer {
        position: relative;
        padding: 1em 2em;
        z-index: 0;
    }
    .contact-number {
        font-size: 1.3em;
    }
    #whatsapp,
    #wechat {
        margin-top: 0.5em;
        width: 26px;
    }

    #who-we-are-text {
        width: 80vw;
    }

    #services-icons-section {
        padding: 4em 10%;
    }

    #footer h3 {
        margin-top: 2em;
    }

    .footer-logo {
        width: 160px !important;
        margin-top: 2em;
        margin-bottom: 1em !important;
    }

    .footer-link a {
        margin: 0 !important;
        font-size: 0.8em;
    }

    .contacts {
        margin-top: 0.5em;
        margin-left: 1em;
        width: 100% !important;
    }

    .contact-img {
        height: 1.1em;
        margin-top: 0;
    }

    .theme-btn {
        font-size: 0.7em !important;
    }

    /* about pg phone */
    .header-img {
        height: 100px !important;
        padding-top: 30px !important;
    }

    .about-content-sub {
        margin-bottom: 2em !important;
        display: block;
    }

    .about-img {
        width: 100% !important;
    }

    .about-content {
        padding-bottom: 1em !important;
    }

    /* about us philosophy */
    #phil-box .col {
        padding: 1em !important;
        font-size: 0.8em;
    }

    .value {
        font-size: 0.8em !important;
    }
    #phil-box {
        margin: 0 !important;
        margin-top: -1em !important;
    }

    #phil-box h3 {
        margin-top: 1em !important;
    }

    #phil-section {
        padding: 0 !important;
    }

    .value {
        margin: 1em 0.2em !important;
        width: 10em !important;
        padding: 0.5em 2em !important;
        text-align: center !important;
    }

    .about-text p {
        width: 80vw;
        text-align: center;
        margin: 8vh auto;
    }

    /* service page phone */
    .service-L {
        display: none;
    }

    .service-R {
        margin-top: 2em;
        width: 100% !important;
        padding: 0 1em !important;
    }

    .service-R .row {
        margin-top: 0 !important;
    }

    .dropdown-item {
        font-size: 0.8em !important;
    }

    /* form toggle */
    .form-toggle form {
        max-height: 520px;
        max-width: 85vw;
        padding: 1em 0.5em;
        margin: 1em 0.5em;
        height: 75vh;
        overflow: scroll;
    }
    .form-toggle form button {
        margin-top: 1em;
    }
    .form-toggle-chat {
        width: 3.2em;
        height: 3.2em;
    }
    .form-toggle-icon {
        width: 1.6em;
        padding-top: 0.2em;
    }

    /*location page*/
    .location-text {
        max-width: 80vw;
    }

    .location-title {
        padding-top: 1em !important;
    }

    .location {
        padding: 5%;
    }

    .location .col-md {
        margin-bottom: 1em;
    }
    .location-squares-img {
        max-width: 80vw;
        height: 25vh;
        box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
    }

    .location-squares {
        margin-top: 2em;
    }
}
